// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

#back-to-top {background-color: #c3c3c3; padding: 10px 5px 10px 5px; border-radius: 10px; font-size: 18px; color: #fff;position: fixed;bottom: 20px; right: 30px; z-index: 99;display: none;}
#back-to-top i {color: #fff; padding: 15px; border-radius: 10%;}
#back-to-top:hover {
	background-color: #51a4fb;
	text-decoration:none;
}